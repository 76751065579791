@import '~emoji-mart/css/emoji-mart.css';
@import '~@draft-js-plugins/static-toolbar/lib/plugin.css';

@import './fonts.css';

body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Gentleman', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga' 0;
  overscroll-behavior-y: contain;
}

* {
  box-sizing: border-box !important;
}

html {
  min-height: 100vh;
  height: 100%;
}

#root {
  height: 100%;
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

input,
textarea,
select {
  letter-spacing: 0.02px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

h4 {
  margin: 1em 0;
}

.image-editor {
  width: 100% !important;
}
