.DraftEditor-root {
  background-color: #fff;
  height: 300px;
  width: 100%;
  overflow-y: auto;
  color: black;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-weight: 300;
  text-align: start;
  font-size: 18px;
  }

  .public-DraftEditorPlaceholder-inner { position: absolute; pointer-events: none; color: gray }
  
  .DraftEditor-editorContainer{
    height: 100%;
  }

  .public-DraftEditor-content {
    height: 100%;
  }